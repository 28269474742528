import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Main from './pages/main';
import Provider from './pages/provider';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/provider" element={<Provider />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
